import React, {Fragment} from "react";
import {Popover, Transition} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import Image from "next/image";
import Link from "next/link";

export default function Header() {
  return (
    <Popover className="relative bg-almostblack-500">
      <div className="relative z-20">
        <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
          <div>
            <Link href="/">
              <a className="flex">
                <span className="sr-only">Arke Fitness</span>
                <Image src="/images/arkefitness-logo-inverted.png" height={32} width={110} alt="logo" />
              </a>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-10 font-accent">
              <Link href="/about-us">
                <a className="uppercase text-lg font-bold text-gray-400 hover:text-gray-500">About Us</a>
              </Link>
              <Link href="/services">
                <a className="uppercase text-lg font-bold text-gray-400 hover:text-gray-500">Services</a>
              </Link>
              <Link href="/packages">
                <a className="uppercase text-lg font-bold text-gray-400 hover:text-gray-500">Packages</a>
              </Link>
              <Link href="/contact-us">
                <a className="uppercase text-lg font-bold text-gray-400 hover:text-gray-500">Contact</a>
              </Link>
              <Link href="/affiliates">
                <a className="uppercase text-lg font-bold text-gray-400 hover:text-gray-500">Affiliates</a>
              </Link>
            </Popover.Group>
            <div className="flex items-center md:ml-12">
              <a href="https://wa.me/message/AESXYUNFR6NNK1" target="_blank" rel="noreferrer">
                <a className="ml-8 af-button-primary uppercase">Reach out to us</a>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 sm:pb-8">
              <div className="flex items-center justify-between">
                <div>
                  <Image src="/images/arkefitness-logo-primary.png" height={32} width={110} alt="logo" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-grey-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5">
              <div className="grid grid-cols-2 gap-4">
                <Link href="/about-us">
                  <a className="rounded-md text-lg font-medium text-gray-900 hover:text-gray-700">About us</a>
                </Link>

                <Link href="/services">
                  <a className="rounded-md text-lg font-medium text-gray-900 hover:text-gray-700">Services</a>
                </Link>

                <Link href="/packages">
                  <a className="rounded-md text-lg font-medium text-gray-900 hover:text-gray-700">Packages</a>
                </Link>

                <Link href="/affiliates">
                  <a className="rounded-md text-lg font-medium text-gray-900 hover:text-gray-700">Affiliates</a>
                </Link>
              </div>
              <div className="mt-6">
                <Link href="/contact-us">
                  <a className="af-button-primary w-full">Contact us</a>
                </Link>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
