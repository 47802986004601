import React from "react";

interface Tags {
  type?: string;
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  keywords?: string;
  imageWidth?: number;
  imageHeight?: number;
}

const MetaTags: React.FunctionComponent<Tags> = ({
  type = "website",
  title = "Arke Fitness",
  description = "We bridge the gap between Physiotherapy and Personal Training",
  image = "https://arkefitness.com/images/arkefitness-banner.png",
  url,
  keywords,
  imageWidth = 1200,
  imageHeight = 630
}) => {
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta property="robots" content="index,follow" />
      <meta property="googlebot" content="index,follow" />
      <meta property="description" content={description} />
      <meta property="keywords" content={keywords} />

      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Arke Fitness" />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content={imageWidth.toString()} />
      <meta property="og:image:height" content={imageHeight.toString()} />
      <meta property="og:image:alt" content={title} />

      {url && <meta property="og:url" content={url} />}

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:src" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="facebook-domain-verification" content="zubxmnda8cjv83u39ygkj4h8ah460v" />
    </>
  );
};
export default MetaTags;
